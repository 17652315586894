import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';

import CenterText from '../components/common/CenterText';

const Container = styled.div`
  margin-top: ${(props) => props.theme.navigationHeight};

  p {
    word-break: break-all;
  }
`;

const ImprintPage = ({
  data: {
    datoCmsMisc: { text },
  },
}) => {
  return (
    <Container>
      <CenterText dangerouslySetInnerHTML={{ __html: text }} />
    </Container>
  );
};

export const query = graphql`
  {
    datoCmsMisc(name: { eq: "Impressum" }) {
      name
      text
    }
  }
`;

export default ImprintPage;
